/* eslint-disable i18next/no-literal-string */
import { ReactElement, useEffect } from 'react'
import { CommonLayout } from 'layouts/CommonLayout'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { NextPageWithLayout } from 'types/next'
import { NEXTAUTH_COGNITO_GOOGLE_LOGOUT_URL } from 'constants/common/auth'
import { Route } from 'constants/common/routes'
import { SignInPage } from 'components/sign-in-page'
import { useToast } from 'components/ui/use-toast'
import { withAuthGuard } from 'utils/app/withAuthGuard'
import { serverSideTranslations } from 'utils/i18n'

interface SignInProps {
  userNotFound?: boolean
}

const SignIn: NextPageWithLayout<SignInProps> = ({ userNotFound = false }) => {
  const { t } = useTranslation('signIn')
  const { toast } = useToast()
  const router = useRouter()

  useEffect(() => {
    if (userNotFound) {
      setTimeout(() => {
        void router.push(Route.SignIn, undefined, { shallow: true })
        toast({
          title: "User with these credentials doesn't exist.",
          variant: 'error',
        })
        void signOut({ redirect: false })
        void router.push(NEXTAUTH_COGNITO_GOOGLE_LOGOUT_URL)
      }, 200) // @ns todo avoid hacky timeout
    }
    // prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Head>
        <title>{t('appName')}</title>
        <link rel="icon" href="/logo.svg" />
      </Head>
      <SignInPage />
    </div>
  )
}

SignIn.getLayout = (page: ReactElement) => <CommonLayout>{page}</CommonLayout>

export const getServerSideProps = withAuthGuard(
  { shouldBeLoggedIn: false },
  async (context: GetServerSidePropsContext) => {
    let isUserNotFound = false

    if (context.query?.error === 'userNotFound') {
      // @ns TODO: use constants
      isUserNotFound = true
    }

    return {
      props: {
        ...(await serverSideTranslations(context, ['signIn', 'common'])),
        ...(isUserNotFound && { userNotFound: true }),
      },
    }
  },
)

export default SignIn
