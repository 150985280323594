import { useForm } from 'react-hook-form'
import { BsTwitterX } from 'react-icons/bs'
import { FcGoogle } from 'react-icons/fc'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuth } from 'hooks/useAuth'
import Image from 'next/image'
import { emailSignInSchema, EmailSignInSchemaType } from 'validationSchemas/emailSignIn'
import { CommonButton } from 'components/common-button'
import { CommonFormInputField } from 'components/common-form-input-field'
import { MetaMaskIcon } from 'components/icons/MetaMaskIcon'
import { Form } from 'components/ui/form'

const FORM_ID = 'sign-in-form'

export const SignInStep = () => {
  const { signInMethods, isMetamaskAllowed } = useAuth()
  const formMethods = useForm<EmailSignInSchemaType>({
    resolver: zodResolver(emailSignInSchema),
    mode: 'onChange',
  })

  return (
    <>
      <div className="flex flex-col items-center gap-6">
        <Image src="logo.svg" alt="JamGalaxy logo" height={100} width={100} />
        <div className="flex w-full flex-col items-center">
          <h1 className="text-lg font-light text-white">Welcome to Jam Galaxy</h1>
          <p className="text-sm font-light text-white-transparent60">
            Your Jam Galaxy wallet with one click
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col gap-4">
        <CommonButton
          colorVariant="secondary"
          className="w-full font-medium"
          leftIcon={FcGoogle}
          size="icon-l"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={signInMethods.cognitoGoogle}
        >
          Continue with Google
        </CommonButton>
        <div className="flex gap-4">
          <CommonButton
            colorVariant="secondary"
            className="w-full"
            iconOnly
            icon={BsTwitterX}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={signInMethods.cognitoTwitter}
          />
          <CommonButton
            colorVariant="secondary"
            className="w-full"
            iconOnly
            icon={MetaMaskIcon}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={signInMethods.cognitoMetamask}
            disabled={!isMetamaskAllowed}
          />
        </div>
      </div>
      <Form {...formMethods}>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={formMethods.handleSubmit((formData) => {
            console.log('submitted email sign in', formData)
          })}
          id={FORM_ID}
        >
          <CommonFormInputField<EmailSignInSchemaType>
            disabled
            name="email"
            control={formMethods.control}
            label="Email"
            required
            type="email"
            placeholder="E.g. name@example.com"
          />
        </form>
      </Form>
      <CommonButton disabled className="w-full" type="submit" form={FORM_ID}>
        Continue
      </CommonButton>
    </>
  )
}
